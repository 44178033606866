import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieConsentBannerComponent } from './cookie-consent-banner.component';
import { CookieConsentModalComponent } from '../cookie-consent-modal/cookie-consent-modal.component';
import {
  IdsIconButtonModule,
  IdsIconModule,
  IdsMainButtonModule,
  IdsModalModule,
  IdsSwitchModule,
  IdsFormSelectionGroupModule,
  IdsFormSelectionModule,
} from '@ids/angular';
import { CookieConsentBannerV2Component } from '../cookie-consent-banner-v2/cookie-consent-banner-v2.component';

@NgModule({
  declarations: [
    CookieConsentBannerComponent,
    CookieConsentModalComponent,
    CookieConsentBannerV2Component,
  ],
  imports: [
    CommonModule,
    IdsIconButtonModule,
    IdsIconModule,
    IdsMainButtonModule,
    IdsModalModule,
    IdsSwitchModule,
    IdsFormSelectionGroupModule,
    IdsFormSelectionModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [CookieConsentBannerComponent],
})
export class CookieConsentBannerModule {}
