import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IdsConfigModule } from '@ids/angular';
import { environment } from 'src/environments/environment';
import { MfeStyleManagerModule } from './core/services/mfe-style-manager/mfe-style-manager.module';
import { CookieConsentBannerModule } from './cookie-consent-banner/cookie-consent-banner.module';
import { CookieConsentBannerComponent } from './cookie-consent-banner/cookie-consent-banner.component';
import { createCustomElement } from '@angular/elements';

import { Segments } from '@ids/tools';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    MfeStyleManagerModule,
    IdsConfigModule.forRoot({
      production: environment.production,
      segment: Segments.Varejo,
      // para MFE é recomendado passar o elemento raiz do projeto
      elementToApplySegment: 'itau-cookie-consent-banner',
    }),
    CookieConsentBannerModule,
  ],
})
export class AppModule implements DoBootstrap {
  private cookieConsentBanner = 'itau-cookie-consent-banner';

  constructor(private injector: Injector) {}

  ngDoBootstrap() {
    this.generateCustomElement(
      this.cookieConsentBanner,
      CookieConsentBannerComponent,
    );
  }

  private generateCustomElement(selector: string, component: any) {
    const customElement = createCustomElement(component, {
      injector: this.injector,
    });
    customElements.define(selector, customElement);
  }
}
