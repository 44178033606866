import { Injectable } from '@angular/core';
import { IdsSegmentService } from '@ids/angular';
import { SegmentValueTypes } from '@ids/tools';

@Injectable({
  providedIn: 'root',
})
export class IdsBannerSegmentService {
  private mapSegmentIds = new Map();

  constructor(private idsSegmentService: IdsSegmentService) {}

  createSegmentAliasForBanner() {
    this.mapSegmentIds.set('blackweek', 'blackweek');
    this.mapSegmentIds.set('cartoes', 'itaucard');
    this.mapSegmentIds.set('credicard', 'credicard');
    this.mapSegmentIds.set('empresas', 'empresas');
    this.mapSegmentIds.set('family-office', 'private');
    this.mapSegmentIds.set('ibba', 'itau_bba');
    this.mapSegmentIds.set('investment-services', 'investment_service');
    this.mapSegmentIds.set('magalu', 'magalu');
    this.mapSegmentIds.set('personalite', 'personnalite');
    this.mapSegmentIds.set('personnalite-rebranding', 'personnalite');
    this.mapSegmentIds.set('private-bank', 'private');
    this.mapSegmentIds.set('uniclass', 'uniclass_beta');
    this.mapSegmentIds.set('varejo', 'varejo');
  }

  getIdsSegmentBanner(value: string) {
    return this.mapSegmentIds.has(value)
      ? this.mapSegmentIds.get(value)
      : value.toLowerCase();
  }

  setIdsSegmentBanner(segment: string) {
    const bannerSegment = segment === 'varejo' ? 'varejo' : segment;
    this.idsSegmentService.changeSegment(
      this.getIdsSegmentBanner(bannerSegment) as SegmentValueTypes,
    );
  }
}
