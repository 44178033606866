import { Injectable } from '@angular/core';
import { ɵDomSharedStylesHost } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class MfeStyleManagerService extends ɵDomSharedStylesHost {
  private styles: string[] = [];

  unmountStyles() {
    this.styles = [...this.getAllStyles()];
    this.ngOnDestroy();
  }

  mountStyles() {
    if (this.styles.length > 0) {
      this.addStyles(this.styles);
    }
  }
}
