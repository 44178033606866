<ids-modal
  [(open)]="isOpen"
  (openChange)="openChange($event)"
  width="480px"
  class="cookie-consent-modal">
  <ids-modal-header #header [noDivider]="true">
    <h2 idsModalTitle>Gerenciar cookies</h2>
  </ids-modal-header>

  <form [formGroup]="form" class="ids-px-6">
    <section class="ids-d-flex ids-align-items-center">
      <div class="ids-py-4 ids-pr-2">
        <h3 class="title ids-d-block ids-pb-1">Cookies Essenciais</h3>
        <p class="subtitle">
          Necessários para as funções básicas do site, permitindo que este opere
          corretamente.
        </p>
      </div>
      <ids-form-selection-group>
        <ids-form-selection>
          <input type="checkbox" formControlName="C0001" idsSwitch />
        </ids-form-selection>
      </ids-form-selection-group>
    </section>
    <section class="ids-d-flex ids-align-items-center">
      <div class="ids-py-4 ids-pr-2">
        <h3 class="title ids-d-block ids-pb-1">Cookies de Funcionalidade</h3>
        <p class="subtitle">
          Possibilitam melhor desempenho dos serviços solicitados e lembram
          preferências do site.
        </p>
      </div>
      <ids-form-selection-group>
        <ids-form-selection>
          <input type="checkbox" formControlName="C0003" idsSwitch />
        </ids-form-selection>
      </ids-form-selection-group>
    </section>
    <section class="ids-d-flex ids-align-items-center">
      <div class="ids-py-4 ids-pr-2">
        <h3 class="title ids-d-block ids-pb-1">Cookies de Publicidade</h3>
        <p class="subtitle">
          Personalizam anúncios conforme sua experiência de navegação.
        </p>
      </div>
      <ids-form-selection-group>
        <ids-form-selection>
          <input type="checkbox" formControlName="C0004" idsSwitch />
        </ids-form-selection>
      </ids-form-selection-group>
    </section>
    <section class="ids-d-flex ids-align-items-center">
      <div class="ids-py-4 ids-pr-2">
        <h3 class="title ids-d-block ids-pb-1">Cookies Analíticos</h3>
        <p class="subtitle">
          Coletam dados e informações de uso do site, para melhorar seu
          funcionamento.
        </p>
      </div>
      <ids-form-selection-group>
        <ids-form-selection>
          <input type="checkbox" formControlName="C0002" idsSwitch />
        </ids-form-selection>
      </ids-form-selection-group>
    </section>
    <section class="ids-d-flex ids-align-items-center">
      <div class="ids-py-4 ids-pr-2">
        <p class="subtitle">
          Você pode desativar os cookies em seu navegador. Para saber mais,
          consulte nossa
          <a
            rel="noopener"
            class="ids-link"
            target="_blank"
            href="https://www.itau.com.br/privacidade/politica-de-privacidade-e-cookies"
            (click)="sendAnalyticsEvent()"
            >Política de privacidade e cookies</a
          >.
        </p>
      </div>
    </section>
  </form>

  <ids-modal-footer>
    <button
      idsMainButton
      variant="secondary"
      (click)="handleRejectNotEssentials()">
      Rejeitar não essenciais
    </button>
    <button idsMainButton (click)="handleSave()">Salvar</button>
  </ids-modal-footer>
</ids-modal>
