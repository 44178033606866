import { Injectable } from '@angular/core';

export interface EventGA {
  detailText: string;
  eventName: 'click' | 'screen_view' | 'success' | 'visualization';
}

interface AnalyticsEvent {
  analytics: {
    event_name: string;
    parameters: {
      custom_path: string;
      implementation_team: string;
      detail: string;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  itauDigitalAnalytics: { track: (analyticsEvent: AnalyticsEvent) => void } =
    window['ItauDigitalAnalytics' as keyof typeof window] || null;
  path =
    (window as any).pageNameGA4 && (window as any).pageNameGA4.substring(1)
      ? (window as any).pageNameGA4.substring(1)
      : window.location.pathname
      ? window.location.pathname.substring(1).toLocaleLowerCase()
      : '';
  pageNameGA4 = this.path ? this.sanitizePageName(this.path) : '';

  sanitizePageName(pageName: string): string {
    let newPageName = pageName.trim();

    while (/[^a-zA-Z0-9\s]$/.test(newPageName)) {
      newPageName = newPageName.slice(0, -1);
    }
    return newPageName;
  }

  setCustomPathGA4(pageName?: string) {
    if (this.pageNameGA4) {
      this.pageNameGA4 = this.sanitizePageName(this.pageNameGA4);
    } else {
      this.pageNameGA4 = pageName ? this.sanitizePageName(pageName) : 'home';
    }
  }

  screenviewTrack(eventGa: EventGA) {
    if (!this.itauDigitalAnalytics) {
      return;
    }

    let analytics = {
      event_name: eventGa.eventName,
      parameters: {
        custom_path: `/${this.pageNameGA4}`,
        implementation_team: 'squad-gestao-de-cookies-e-privacidade:martech',
        detail: eventGa.detailText,
      },
    };

    this.itauDigitalAnalytics.track({ analytics });
  }

  clickTrack(eventGa: EventGA) {
    if (!this.itauDigitalAnalytics) {
      return;
    }

    const analytics = {
      event_name: eventGa.eventName,
      parameters: {
        custom_path: `/${this.pageNameGA4}`,
        implementation_team: 'squad-gestao-de-cookies-e-privacidade:martech',
        detail: eventGa.detailText,
      },
    };

    this.itauDigitalAnalytics.track({ analytics });
  }
}
