import { NgModule } from '@angular/core';
import { ɵDomSharedStylesHost } from '@angular/platform-browser';
import { MfeStyleManagerService } from './mfe-style-manager.service';

@NgModule({
  providers: [
    { provide: ɵDomSharedStylesHost, useExisting: MfeStyleManagerService },
  ],
})
export class MfeStyleManagerModule {}
export * from './mfe-style-manager.service';
