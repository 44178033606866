<section [class]="idPrefix" [id]="idPrefix" *ngIf="showBanner">
  <div [class]="idPrefix + '__container'" *ngIf="!isMobile; else mobileScreen">
    <div [class]="idPrefix + '__close'">
      <button
        idsIconButton
        variant="neutral"
        title="fechar"
        (click)="onCloseBanner()">
        fechar
      </button>
    </div>
    <div [class]="idPrefix + '__content'">
      <div [class]="idPrefix + '__content--terms-policy'">
        <p class="ids-body-text" [id]="idPrefix + '-terms-policy'">
          Utilizamos cookies para melhorar a experiência de navegação e
          identificar suas preferências. Você pode aceitar todos os cookies,
          rejeitar os não essenciais ou gerenciar os que serão usados. Leia mais
          em nossa
          <a
            class="ids-link"
            target="_blank"
            rel="noopener"
            href="https://www.itau.com.br/privacidade/politica-de-privacidade-e-cookies"
            (click)="sendAnalyticsEvent({
                eventName: 'click',
                detailText: 'btn:consentimento-cookies:banner:politica-privacidade-cookies',
            })"
            >Política de Privacidade e cookies</a
          >.
        </p>
      </div>
      <div [class]="idPrefix + '__content--buttons'">
        <a
          tabindex="0"
          [id]="idPrefix + '-manage-cookies-link'"
          class="ids-link"
          role="link"
          (click)="openModal()"
          >Gerenciar cookies</a
        >
        <button
          idsMainButton
          variant="secondary"
          [id]="idPrefix + '-reject-cookies-btn'"
          (click)="rejectNonEssentialCookies()">
          Rejeitar não essenciais
        </button>
        <button
          idsMainButton
          [id]="idPrefix + '-accept-cookies-btn'"
          (click)="acceptAllCookies()">
          Aceitar todos
        </button>
      </div>
    </div>
  </div>
  <ng-template #mobileScreen>
    <div [class]="idPrefix + '__container'">
      <div [class]="idPrefix + '__content'">
        <div [class]="idPrefix + '__close'">
          <button
            idsIconButton
            variant="neutral"
            title="fechar"
            (click)="onCloseBanner()">
            fechar
          </button>
        </div>
        <div [class]="idPrefix + '__content--terms-policy'">
          <p class="ids-body-text" [id]="idPrefix + '-terms-policy'">
            Utilizamos cookies para melhorar a experiência de navegação e
            identificar suas preferências. Você pode aceitar todos os cookies,
            rejeitar os não essenciais ou gerenciar os que serão usados. Leia
            mais em nossa
            <a
              rel="noopener"
              class="ids-link"
              target="_blank"
              href="https://www.itau.com.br/seguranca/termos-de-uso"
              (click)="sendAnalyticsEvent({
                eventName: 'click',
                detailText: 'btn:consentimento-cookies:banner:politica-privacidade-cookies',
              })"
              >Política de Privacidade e cookies</a
            >.
          </p>
        </div>
      </div>
      <hr class="ids-divider -soft" />
      <div [class]="idPrefix + '__content--buttons'">
        <a
          tabindex="0"
          [id]="idPrefix + '-manage-cookies-link'"
          class="ids-link"
          role="link"
          (click)="openModal()"
          >Gerenciar cookies</a
        >
        <button
          idsMainButton
          variant="secondary"
          [id]="idPrefix + '-reject-cookies-btn'"
          (click)="rejectNonEssentialCookies()">
          Rejeitar não essenciais
        </button>
        <button
          idsMainButton
          [id]="idPrefix + '-accept-cookies-btn'"
          (click)="acceptAllCookies()">
          Aceitar todos
        </button>
      </div>
    </div>
  </ng-template>
</section>
<itau-cookie-consent-modal
  [isOpen]="isOpenModal"
  [userHasAlreadyInteracted]="isShowPreferencesButton"
  (onChange)="onChange($event)"
  (userHasInteractedToModal)="
    userHasInteractedToModal = $event
  "></itau-cookie-consent-modal>
<button
  class="fab-button"
  aria-label="Gerenciar cookies"
  role="button"
  (click)="showModal()"
  *ngIf="isShowPreferencesButton">
  <ids-icon size="L">configuracao_outline</ids-icon>
</button>
